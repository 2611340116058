import React, { useState } from "react";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
const Container = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;

  .information {
    text-align: center;
    margin-top: 5rem;
    font-size: 20px;
  }
`;

export default ({}) => {
  return (
    <Container>
      <div className="information">
        <br />
        <Spinner animation="border" />
      </div>
    </Container>
  );
};
