import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderRouter from "Router/HeaderRouter";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { authService } from "myFirebase";
import { AuthProvider } from "Hook/useAuth";
import { UserProvider } from "Hook/useUser";
const GlobalStyles = createGlobalStyle`
  body {
    font-family:"SCDream2";
  }
`;

function App() {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <AuthProvider>
        <UserProvider>
          <HeaderRouter />
        </UserProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
