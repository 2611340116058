import React, { useState, useEffect } from "react";
import Loading from "Page/MainPage/Loading";
import HomePresenter from "./HomePresenter";
import { dbService } from "myFirebase";
export default () => {
  const [nftList, setNftList] = useState("");
  useEffect(() => {
    dbService.collection("nfts").onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNftList(data);
    });
  }, []);
  if (nftList == "") {
    return <Loading />;
  }

  const mintingData = {};
  const canlendarData = [];

  nftList.sort(function (a, b) {
    if (a.date > b.date) {
      return 1;
    }
    if (a.date < b.date) {
      return -1;
    }

    return 0;
  });
  nftList.map((v) => {
    if (v.name in mintingData) {
      mintingData[v.name].push(v);
    } else {
      mintingData[v.name] = [];
      mintingData[v.name].push(v);
    }
    if (v.time != "미정") {
      canlendarData.push({
        title: v.name,
        date: v.date,
      });
    } else {
      canlendarData.push({
        title: v.name,
        date: v.date.split(" ")[0] + " 미정",
      });
    }
  });

  return (
    <HomePresenter canlendarData={canlendarData} mintingData={mintingData} />
  );
};
