import React, { useState } from "react";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;

  .information {
    text-align: center;
    margin-top: 5rem;
    font-size: 20px;
  }

`;

export default ({ }) => {
  return (
    <Container>
      <div className='information'>
        로그인이 필요한 페이지 입니다.
        <br />
        오른쪽 상단의 로그인 버튼을 눌러주세요.
      </div>
    </Container >
  );
};
