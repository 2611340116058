import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useMount } from "react-use";
import { firebaseInstance, authService } from "myFirebase";

const AuthContext = createContext();

function useAuthClient() {
  const [isInitialized, setInitialized] = useState(false);
  const [user, setUser] = useState(null);

  const signIn = useCallback(async () => {
    const provider = new firebaseInstance.auth.GoogleAuthProvider();
    return await authService.signInWithPopup(provider);
  }, []);

  const signOut = useCallback(async () => {
    return await authService.signOut();
  }, []);

  useMount(() => {
    authService.onAuthStateChanged((user) => {
      setUser(user);
      setInitialized(true);
    });
  });

  return useMemo(
    () => ({
      isInitialized,
      isAuthorized: user !== null,
      signIn,
      signOut,
      user,
    }),
    [isInitialized, user, signIn, signOut]
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const authClient = useAuthClient();

  return (
    <AuthContext.Provider value={authClient}>{children}</AuthContext.Provider>
  );
}

export default useAuth;
