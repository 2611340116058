import React, { useState } from "react";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;

  .title {
    text-align: center;
    font-weight: bolder;
    margin-top: 5rem;
    font-size: 50px;
  }

  .alert {
    text-align: center;
    margin-top: 2rem;
    font-size: 20px;
  }
`;

export default ({}) => {
  return (
    <Container>
      <div className="title">404</div>
      <div className="alert">
        페이지를 찾지 못했습니다.
        <br />
        주소를 다시 확인해주세요.
      </div>
    </Container>
  );
};
