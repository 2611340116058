import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { Dropdown, Menu } from "antd";
import useUser from "Hook/useUser";
import { main_color } from "Color.js";
const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto;
  grid-template-areas: "upper";
  .upper {
    grid-area: upper;
    height: inherit;
    box-shadow: 2px 3px 5px -5px gray;
    .header {
      display: grid;
      grid-template-areas: "a title menu";
      grid-template-columns: 4rem auto 4rem;
      height: inherit;
      align-items: center;

      .title {
        .name {
          font-size: 33px;
          text-align: center;
          font-weight: 900;
          color: ${main_color};
          cursor: pointer;
          font-family: "SB_M";
        }
      }
      .menu {
        display: flex;
        align-items: center;

        .login {
          font-size: 12px;
          text-align: center;
          color: white;
          cursor: pointer;
          margin-right: 1rem;
          padding-right: 0.3rem;
          padding-left: 0.3rem;
          padding-top: 0.3rem;
          padding-bottom: 0.3rem;
          background-color: ${main_color};
          border-radius: 10px;
        }
      }
    }
  }
`;
function Header() {
  const user = useUser();
  const history = useHistory();

  const handleLogin = useCallback(async () => {
    user.login();
    history.push("/");
  }, [user, history]);

  const handleLogout = useCallback(async () => {
    user.logout();
    history.push("/");
  }, [user, history]);

  const handleClick = async ({ key }) => {
    if (key === "logout") {
      await handleLogout();
    }
  };

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.ItemGroup title={`${user.data?.name}`}></Menu.ItemGroup>
      <Menu.Item key="logout">Logout</Menu.Item>
    </Menu>
  );

  return (
    <Container>
      <div className="upper">
        <div className="header">
          <div></div>

          <div className="title">
            <div
              onClick={() => {
                history.push("/");
              }}
              className="name"
            >
              모두의 민팅
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default Header;
