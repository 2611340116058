import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import useAuth from "Hook/useAuth";
import { dbService } from "myFirebase";

export default useUserContext;

function useUser() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const auth = useAuth();
  useEffect(async () => {
    if (!auth.isInitialized) {
      setLoading(true);
      return;
    }

    if (!auth.isAuthorized) {
      setData(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    setData(await getOrCreateUserData(auth.user));
    setLoading(false);
  }, [auth]);

  const login = useCallback(async () => {
    await auth.signIn();
  }, [auth]);

  const logout = useCallback(async () => {
    await auth.signOut();
  }, [auth]);

  return useMemo(
    () => ({
      loading: loading,
      isLoggedIn: data !== null,
      data,
      login,
      logout,
    }),
    [loading, data, login, logout]
  );
}

async function getOrCreateUserData(user) {
  const userRef = dbService.collection("users").doc(user.uid);

  const doc = await userRef.get();
  if (doc.exists) {
    return packUserData(user, doc.data());
  }

  const initialData = {
    name: user.displayName,
    email: user.email,
  };

  await userRef.set(initialData);
  return packUserData(user, initialData);
}

function packUserData(user, docData) {
  return {
    uid: user.uid,
    ...docData,
  };
}

const UserContext = createContext();

function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const user = useUser();

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
