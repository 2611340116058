import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import MainRouter from "./MainRouter";
import Header from "Page/HeaderPage/Header";
import { isMobile } from "react-device-detect";
import useUser from "Hook/useUser";
import Loading from "Page/MainPage/Loading";
const GlobalStyle = createGlobalStyle`
html{
  width: 100%;
  height:100%;
  margin:0px;
}
  body {
    width: 100%;
    height:100%;
    margin:0px;
   
  }
  #root {
  width: 100%;
  height: 100%;
}
`;
const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 3rem auto ${isMobile ? "3rem" : "0"};
  grid-template-columns:
    ${isMobile ? "auto" : "1fr"} 3fr
    ${isMobile ? "auto" : "1fr"};
  grid-gap: 0rem;

  grid-template-areas:
    " header header header"
    "left main right"
    "footer footer footer";

  .header {
    grid-area: header;
  }
  .left {
    grid-area: left;
    /* background-color: #f4e8ff; */
  }
  .right {
    grid-area: right;
    /* background-color: #f4e8ff; */
  }
  .footer {
    grid-area: footer;
    /* background-color: #f4e8ff; */
  }
  .main {
    /* overflow: auto; */
    grid-area: main;
  }
`;

function HeaderRouter() {
  const user = useUser();

  return (
    <React.Fragment>
      <GlobalStyle />
      {user.loading ? (
        <Loading />
      ) : isMobile ? (
        // mobile page
        <Container>
          <div className="header">
            <Header />
          </div>
          <div className="left"></div>
          <section className="main">
            <MainRouter />
          </section>
          <div className="footer"></div>
        </Container>
      ) : (
        // PC page
        <Container>
          <div className="header">
            <Header />
          </div>
          <div className="left"></div>
          <div className="main">
            <MainRouter />
          </div>
          <div className="right"></div>
        </Container>
      )}
    </React.Fragment>
  );
}

export default HeaderRouter;
