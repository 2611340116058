import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import Home from "Page/MainPage/Home";
import Alert from "Page/MainPage/Alert";
import PageError from "Page/MainPage/PageError";
import useUser from "Hook/useUser";
const Container = styled.div``;

function PrivateRoute({ children, ...rest }) {
  const user = useUser();

  if (user.isLoggedIn) {
    return <Route {...rest} />;
  } else {
    return (
      <Route
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/Alert",
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
}

function MainRouter() {
  return (
    <Container>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/Alert" component={Alert} />
        <Route path="*" component={PageError} />
      </Switch>
    </Container>
  );
}
export default MainRouter;
