import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import _ from "lodash";
import { main_color, text_color } from "Color.js";
import MyCalendar from "Component/MyCalendar";
import Table from "react-bootstrap/Table";
import Fab from "@mui/material/Fab";
import AdfitWebComponent from "react-adfit-web-component";
import {
  BsLink,
  BsDiscord,
  BsFillArrowDownSquareFill,
  BsFillArrowUpSquareFill,
  BsTwitter,
} from "react-icons/bs";
import { RiKakaoTalkFill } from "react-icons/ri";

const Container = styled.div`
  display: flex;
  margin-top: 1rem;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  .sitead {
    text-align: center;
    font-size: 0.8rem;
    color: ${text_color};
    border: 1px solid black;

    .iconcontainer {
      display: flex;
      font-size: 1.5rem;
      color: black;
      justify-content: center;
      .icon {
        margin-right: 1rem;
        cursor: pointer;
      }
    }
  }
  .siteinformation {
    text-align: center;
    font-size: 0.8rem;
    color: ${text_color};
    border: 1px solid black;
    margin-bottom: 1rem;
  }
  .information {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    color: ${text_color};
  }
  .filter {
    text-align: center;
    font-size: 1rem;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    color: ${text_color};
  }
  .input {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .category.nav.nav-pills {
    margin-right: 1rem;
    .nav-item {
      .active {
        background: ${main_color} !important;
        color: white !important;
      }
      > a {
        color: ${main_color} !important;
      }
    }
  }

  .CardContiner {
    display: flex;
    justify-content: flex-start;
    margin-top: 3rem;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 1rem;
    margin-right: 1rem;
    .card {
      display: flex;
      flex-direction: column;
      .card-body {
        .card-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .card-homepage {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            align-items: center;
            text-align: center;
            .icon {
              margin-left: 0.5rem;
              align-items: center;
              justify-content: center;
            }
          }
          .card-name {
            font-weight: bolder;
            font-size: 20px;
          }
        }
        .card-small-title {
          cursor: pointer;
        }

        .card-table {
          font-size: 0.7rem;
          .minting-name {
            font-size: 1rem;
            font-weight: bolder;
            text-align: center;
            .table {
              table-layout: fixed;
            }
          }
        }
      }
      margin-bottom: 1rem;
    }
  }
`;

export default ({ canlendarData, mintingData }) => {
  const history = useHistory();

  const KLAYTN = "KLAY";
  const OTHERS = "Other coin";

  const cardRef = useRef([]);
  const [coin, setCoin] = useState(KLAYTN);
  const [openList, setOpenList] = useState([]);

  const onClickOpen = (idx) => {
    setOpenList((openList) => [...openList, idx]);
  };
  const onClickClose = (idx) => {
    setOpenList(
      _.remove(openList, (inArray) => {
        return inArray != idx;
      })
    );
  };

  return (
    <Container>
      <Fab
        color="primary"
        aria-label="add"
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
          zIndex: 100,
        }}
        onClick={() => {
          cardRef.current["home"].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        <BsFillArrowUpSquareFill />
      </Fab>
      <div className="sitead" ref={(el) => (cardRef.current["home"] = el)}>
        좋은 친구들 NFT 커뮤니티
        <br />
        <div className="iconcontainer">
          <RiKakaoTalkFill
            className="icon"
            onClick={() => {
              window.open("https://open.kakao.com/o/gejchHUd ");
            }}
          />
          <BsTwitter
            className="icon"
            onClick={() => {
              window.open("https://twitter.com/goodfriend0906 ");
            }}
          />{" "}
          <BsDiscord
            className="icon"
            onClick={() => {
              window.open("https://discord.gg/111 ");
            }}
          />
        </div>
      </div>
      <AdfitWebComponent adUnit="DAN-orKK2PjOEK9idkHZ" />
      <div className="siteinformation">
        광고 문의, 민팅 일정 추가 및 수정은 <br />
        아래의 이메일로 제보 부탁드립니다.
        <br />
        moduminting@gmail.com
      </div>

      <div className="information">NFT 민팅 일정</div>
      <MyCalendar
        canlendarData={canlendarData}
        cardRef={cardRef}
        onClickOpen={onClickOpen}
      />

      <div className="filter">
        NFT 민팅 상세 정보
        <Nav variant="tabs" defaultActiveKey={coin} className="category">
          <Nav.Item>
            <Nav.Link
              eventKey={KLAYTN}
              onClick={() => {
                setCoin(KLAYTN);
              }}
            >
              {KLAYTN}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div className="CardContiner">
        {_.map(mintingData, (v, key) => {
          return (
            <div className="card" key={v[0].id}>
              <div
                className="card-body"
                ref={(el) => (cardRef.current[key] = el)}
              >
                <div className="card-title">
                  <div className="card-name">{v[0].name}</div>
                  <div
                    className="card-homepage"
                    onClick={() => {
                      window.open(v[0].web_url);
                    }}
                  >
                    공식 트위터
                    <div className="icon">
                      <BsTwitter />
                    </div>
                  </div>
                </div>
                <div
                  className="card-small-title"
                  onClick={() => {
                    if (_.includes(openList, key)) {
                      onClickClose(key);
                    } else {
                      onClickOpen(key);
                    }
                  }}
                >
                  민팅 일정
                  {_.includes(openList, key) ? (
                    <BsFillArrowUpSquareFill />
                  ) : (
                    <BsFillArrowDownSquareFill />
                  )}
                </div>
                <div className="card-table">
                  {_.includes(openList, key) ? (
                    _.map(v, (val, idx) => {
                      return (
                        <div key={val.name + idx}>
                          <div className="minting-name">{val.minting_name}</div>
                          <Table hover>
                            <tbody>
                              <tr>
                                <th>날짜</th>
                                <th>가격</th>
                                <th>물량</th>
                                <th width="40%">자격조건</th>
                              </tr>
                              <tr>
                                <td>{val.date}</td>
                                <td>
                                  {val.price == "nan" ? "-" : val.price} klay
                                </td>
                                <td>
                                  {val.amount == "nan" ? "-" : val.amount}
                                </td>
                                <td>
                                  {val.qualification == "nan"
                                    ? "-"
                                    : val.qualification}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};
