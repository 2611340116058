import React from "react";
import styled from "styled-components";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import { isMobile } from "react-device-detect";

const CalendarContainer = styled.div`
  font-size: 0.7rem !important;
  color: black !important;
  text-align: center;
  margin: 1rem;
  .fc-toolbar h2 {
    display: inline;
  }

  .fc-daygrid-event-dot {
    margin: 0px;
    border: 0px;
  }
`;
function MyCalendar({ canlendarData, cardRef, onClickOpen }) {
  return (
    <CalendarContainer>
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin]}
        initialView={isMobile ? "dayGridMonth" : "dayGridMonth"}
        weekends={true}
        events={canlendarData}
        dayMaxEventRows={isMobile ? 0 : 2}
        locale={"ko"}
        headerToolbar={{
          left: "",
          center: "prev title next",
          right: "",
        }}
        eventClick={(info) => {
          onClickOpen(info.event.title);
          cardRef.current[info.event.title].scrollIntoView({
            behavior: "smooth",
          });
        }}
        height={isMobile ? 400 : 700}
      />
    </CalendarContainer>
  );
}

export default MyCalendar;
